/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');

* {
  font-family: 'Roboto', sans-serif;
}

ion-toolbar {
  --background: #015A9E;
  color: #FEB536;
  padding-right: 15px;

  ion-icon {
    font-size: 1.5em;
  }

  ion-label {
    margin-right: 15px;
  }
}

.in-segment {
  background: #015A9E;
  color: #FEB536;
}

.segment-button-checked {
  background: #FEB536;
  color: #015A9E;
}

.btn-primary {
  --background: #015A9E;
  color: #FEB536;
}

.btn-accent {
  --background: #FEB536;
  color: #015A9E;
}


.dp-main, .dp-day-calendar-container {
  width: 100% !important;
}

.dp-main {
  div {
    right: 0 !important;
  }
}

.dp-weekdays {
  background: #3880ff;

  display: flex;
  .dp-calendar-weekday {
    flex: 1;
    color: #FFF !important;
    font-size: 16px !important;
  }
}

.dp-calendar-week {
  display: flex;
  button {
    flex: 1;
  }
}

.dp-input-container {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  .dp-picker-input {
    width: 100% !important;
    margin: 0 auto;

    font-weight: bold;
    padding: 10px;
    text-align: center;
    font-size: 18px !important;
  }
}

.invalid-day {
  background: red !important;
}

.dp-nav-header-btn {
  background: #FFF;
  font-size: 20px;
}

.dp-calendar-nav-container {
  height: 40px !important;
}

.dp-calendar-nav-right {
  background: #FFF;
  padding-right: 20px;
}

.dp-calendar-nav-left {
  background: #FFF;
  padding-right: 20px;
}

.dp-weekdays {
  background: #015A9E;
}

.inner-step__block {
  dp-day-calendar {
    width: 95% !important;
  }
}